import { FC } from 'react'
import { PageContent } from '../PageContent'

export const NotFoundPage: FC = () => {
  return <PageContent>
    <h1>Page Not Found</h1>
    <p>It looks like this page might not exist, or you don&apos;t have access to it.</p>
    </PageContent>
}

export default NotFoundPage
