
export const formatFCPElements = (element: any) => {
  const localClone = {...element}
  let typeName

  if (localClone['__typename'].includes('Page_Universalcontenteditor_FlexibleContent_')) {
    typeName = localClone['__typename'].replace('Page_Universalcontenteditor_FlexibleContent_', '')
  } else if (localClone['__typename'].includes('Campaign_Campaignsfcp_FlexibleContentNews_')) {
    typeName = localClone['__typename'].replace('Campaign_Campaignsfcp_FlexibleContentNews_', '')
  } else if (localClone['__typename'].includes('NewsArticle_Newsfcp_FlexibleContentNews_')) {
    typeName = localClone['__typename'].replace('NewsArticle_Newsfcp_FlexibleContentNews_', '')
  } else if (localClone['__typename'].includes('Benefit_Benefitsfields_FlexibleContentBenefits_')) {
    typeName = localClone['__typename'].replace('Benefit_Benefitsfields_FlexibleContentBenefits_', '')
  } else if (localClone['__typename'].includes('Notice_Legalnoticesfcp_FlexibleContentNews_')) {
    typeName = localClone['__typename'].replace('Notice_Legalnoticesfcp_FlexibleContentNews_', '')
  }
  delete localClone['__typename']

  return {
    component: typeName,
    ...localClone,
  }
}
