import pxToRem from '@app/ui/utils/pxToRem'
import { FC } from 'react'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import FCPLayout from '@ui/components/FCPLayout'
import IssuesAtWork from '@ui/components/IssuesAtWork'
import BackToLink from '@ui/components/FCPPageContainer/BackToLink'
import { FCPPageContainerProps } from './types'
import { useUser } from '@app/lib/hooks/useUser'
import { IssuesAtWorkProps } from '../IssuesAtWork/types'
import { NotFoundPage } from '@app/ui/components/Fragments/not-found'
import { formatFCPElements } from '@app/lib/fcpUtils'
import { getActiveBranchFromSession } from '@app/lib/utils'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${media.desktop`
    flex-direction: row-reverse;
    column-gap: ${pxToRem(51)};
    justify-content: flex-end;
  `}
  /* The rule below is to fix a bug where having more than one link in FCP causes the a + a rule to be applied when it shouldn't. */
  /* This occurs because our FCP renders the HTML between the two <a> tags as plain text (not a <p> element) */
  a + a[href^="tel:"],
  a + a[href^="mailto:"],
  a + a[href^="http:"],
  a + a[href^="https:"] {
    margin-left: 0;
  }
`

export const SidebarWrapper = styled.div<{
  topMargin?: string
}>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.desktop`
    width: 33%;
    flex-shrink: 0;
    padding-top: 0;
    margin-top: ${(props: any) => props.topMargin ? '-3.563rem' : '2.3125rem'}};
  `}
`

export const SidebarInner = styled.div`
  display: flex;
  flex-direction: column-reverse;
  ${media.desktop`
    flex-direction: column;
  `}
`

export const FCPLayoutWrapper = styled.div`
  padding-top: ${pxToRem(27)};
  ${media.desktop`
    flex-grow: 1;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: ${pxToRem(54)};
  `}
  a {
    text-decoration: underline;
  }
`

export const PostsFCPLayoutWrapper = styled.div`
  background: #fff;
  border: 1px solid #C5D9E580;
  border-radius: 7px;
  padding-top: ${pxToRem(19)};
  padding-bottom: ${pxToRem(19)};
  padding-left: ${pxToRem(15)};
  padding-right: ${pxToRem(15)};
  ${media.desktop`
    min-width: ${pxToRem(352)};
    border-radius: 10px;
    padding-top: ${pxToRem(39)};
    padding-bottom: ${pxToRem(47)};
    padding-left: ${pxToRem(30)};
    padding-right: ${pxToRem(30)};
  `}
  ${media.giant`
    min-width: ${pxToRem(450)}
  `}
  ${media.veryGiant`
    min-width: ${pxToRem(652)}
  `}
`

export const FCPPageContainer: FC<FCPPageContainerProps> = ({
  fcpElements,
  backToLink,
  payRateComponent,
  issuesAtWorkEnabled = false,
  postsHeader,
}) => {
  const { session } = useUser()
  const branch = getActiveBranchFromSession(useUser().session)?.toLowerCase()

  const issuesAtWorkComponent: IssuesAtWorkProps = {
    awardLink: `/${branch ? branch : '#'}/your-award-or-agreement`,
    branch: getActiveBranchFromSession(session),
  }
  const formattedElements = fcpElements?.map(formatFCPElements)

  const hasSidebar = !!(payRateComponent || issuesAtWorkEnabled)

  return (
    <Container>
      {hasSidebar && (
        <SidebarWrapper topMargin={backToLink?.linkText}>
          <SidebarInner>
            {backToLink && <BackToLink {...backToLink} />}
            {issuesAtWorkEnabled && <IssuesAtWork {...issuesAtWorkComponent} mobileCollapsable={true} />}
          </SidebarInner>
        </SidebarWrapper>
      )}
      {postsHeader ?
        <PostsFCPLayoutWrapper>
          {postsHeader}
          {formattedElements ?
            <FCPLayout fcpElements={formattedElements} />
          : <NotFoundPage />}
        </PostsFCPLayoutWrapper>
        :
        <FCPLayoutWrapper>
          <FCPLayout fcpElements={formattedElements} />
        </FCPLayoutWrapper>
        }
    </Container>
  )
}

export default FCPPageContainer
