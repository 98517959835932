import React, { FC } from 'react'
import styled from 'styled-components'
import pxToRem from '@app/ui/utils/pxToRem'
import { FCPLayoutProps } from './types'
import { useApp } from '@app/lib/hooks/app'
import { media } from 'styled-bootstrap-grid'

import { componentMap as dynamicComponents } from './componentMap'

const Wrapper = styled.div<{
  backgroundColor?: string,
  firstSection: boolean,
  afterNoBackground: boolean,
  isSpacer: boolean,
  afterSpacer: boolean,
  headingExceptionMargin: boolean,
}>`
  ${props => props.backgroundColor && `
    background-color: ${props.backgroundColor};
    border: 1px solid #C5D9E580;
    border-radius: 7px;
    padding-top: ${pxToRem(28)};
    padding-bottom: ${pxToRem(28)};
    @media screen and (min-width: 992px) {
      padding-top: ${pxToRem(40)};
      padding-bottom: ${pxToRem(40)};
    }
  `}
  ${props => !props.firstSection && !props.backgroundColor !== props.afterNoBackground && !props.isSpacer && !props.afterSpacer && `
    margin-top: ${props.headingExceptionMargin ? '1.875rem' : '1.8125rem'};
    @media screen and (min-width: 992px) {
      margin-top: ${props.headingExceptionMargin ? '1.875rem' : '3.375rem'};
    }
  `}

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: ${pxToRem(20)};

    ${media.desktop`
    margin-bottom: ${pxToRem(30)};
    `}
  }
  h4 {
    font-size: 1.1rem;

    ${media.desktop`
    font-size: 1.4rem;
    `}
  }
  p {
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: ${pxToRem(20)};

      ${media.desktop`
      margin-bottom: ${pxToRem(30)};
      `}
    }

    &.primary-text {
      font-size: ${pxToRem(14)};
      color: #21496C;
      font-weight: 500;
      ${media.desktop`
        font-size: ${pxToRem(18)};
      `}
    }
  }
  img {
    border-radius: 7px;
    display: block;
    height: auto;
    max-width: 100%;
  }
  .button-container{
    display: flex;
    align-items: center;
    gap: ${pxToRem(20)};
    flex-wrap: wrap;
  }
  ul {
    li {
      list-style-type: none;
      position: relative;
      padding-left: 25px;

      &:before {
        position: absolute;
        left: -5px;
        top: 5px;
        content: "";
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="%2300AFD9" d="M505 273c9.4-9.4 9.4-24.6 0-33.9L369 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l95 95L24 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l406.1 0-95 95c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0L505 273z"/></svg>');
        height: 15px;
        width: 15px;
        background-size: cover;

        @media screen and (max-width: 992px) {
          left: -5px;
          top: 7px;
          height: 12px;
          width: 12px;
        }
      }
    }
  }
`

export const FCPLayout: FC<FCPLayoutProps> = ({ fcpElements }) => {
  const { isMobile, isDesktop } = useApp()

  return (
    <>
      {fcpElements?.map((fcpElement, index) => {
        if ((isMobile && fcpElement?.hideMobile) || (isDesktop && fcpElement?.hideDesktop)) return null

        const { component, children, ...props } = fcpElement

        const hasImpl = Object.keys(dynamicComponents).includes(component)

        const Router = hasImpl ?
          dynamicComponents[component] : dynamicComponents['NotImplemented']

        if (!hasImpl) props.typename = component

        const { backgroundColor } = props
        const firstSection = index === 0
        const afterNoBackground = !firstSection && fcpElements[index - 1].props?.backgroundColor
        const isSpacer = component === 'Spacer'
        const afterSpacer = !firstSection && fcpElements[index - 1].component === 'Spacer'

        let headingExceptionMargin = false
        if (index > 0 && fcpElements[index - 1]?.component === 'Heading') {
          headingExceptionMargin = true
        }

        return (
          <Wrapper key={index} {...{
            backgroundColor,
            firstSection,
            afterNoBackground,
            isSpacer,
            afterSpacer,
            headingExceptionMargin,
          }}>
            <Router {...props}>
              {children && children.length > 0 && <FCPLayout fcpElements={children} />}
            </Router>
          </Wrapper>
        )
      })}
    </>
  )
}

export default FCPLayout
