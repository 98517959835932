import pxToRem from '@app/ui/utils/pxToRem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import Link from 'next/link'
import { FC } from 'react'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import { BackToLinkInterface } from './types'

const BackToLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary};
  opacity: 0.7;
  cursor: pointer;
  margin-bottom: ${pxToRem(28)};
  margin-top: ${pxToRem(15)};
  transition: all linear 0.15s;

  &:hover {
    color: #ff6820;
  }
  ${media.desktop`
    padding-top: 0};
  `}
`

const BackToLinkIcon = styled(FontAwesomeIcon)`
  font-size: ${props => props.theme.fontSizes.smallx};
`

const BackToLinkText = styled.div`
  font-size: ${props => props.theme.fontSizes.small2x};
  font-weight: 500;
  text-decoration: underline;
  margin-left: ${pxToRem(8)};
  ${media.desktop`
    font-size: ${pxToRem(16)};
  `}
`

const BackToLink: FC<BackToLinkInterface> = ({ linkText, linkUrl, onClick }) => {
  return (
    <Link href={linkUrl} passHref legacyBehavior>
      <BackToLinkWrapper onClick={onClick}>
        <BackToLinkIcon icon={faChevronLeft} />
        <BackToLinkText>{linkText}</BackToLinkText>
      </BackToLinkWrapper>
    </Link>
  )
}

export default BackToLink
